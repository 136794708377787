// Preloader.js
import React, { Component } from 'react';
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import preloader from "../../assets/images/preloaders/vaultboy-favico.gif";

const Preloaders = styled.div`
.preloader {
    width: 100%
    height:100%;
    background-image: url(${preloader});
    background-size: 5%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    opacity: 1;
    transition: 0.3s ease-in-out;
    z-index: 99999;
    @media(max-width:892px){
      flex-direction: column;
    }
}
`;

const Preloader = () => {
    return (
        <Preloaders>
            <div className="preloader">
                <div className="spinner"></div>
            </div>
        </Preloaders>
    );
};

export default Preloader;